<template>
  <div class="popup_wrap" style="width:800px; height:auto;">
    <!--  popup_wrap -->
    <button class="layer_close" @click.prevent="layerClose()">close</button>
    <div class="popup_cont">
      <!-- popup_cont -->

      <h1 class="page_title">{{ $t('msg.NEWB010P020.001') }}</h1><!-- 불러오기 -->
      <div class="content_box mt10">
        <!-- content_box -->
        <form id="bookingCall" @submit.prevent>
          <div class="booking_pop_wrap">
            <ul class="grid">
              <li>
                <div class="form_wrap">
                  <label for="">{{ $t('msg.CSBK100.012') }}</label><!-- POL -->
                  <span style="display:inline-block">
                    <e-auto-complete-place @change="changePol" v-model="formData.podCtrCd" class="wid150" />
                  </span>
                </div>
              </li>
              <li>
                <div class="form_wrap">
                  <label for="">{{ $t('msg.CSBK100.013') }}</label><!-- POD -->
                  <span style="display:inline-block">
                    <e-auto-complete-place @change="changePod" v-model="formData.polCtrCd" class="wid150" />
                  </span>
                </div>
              </li>
              <li>
                <div class="form_wrap">
                  <label for="">{{ $t('msg.NEWB010P020.004') }}</label><!-- 실화주 -->
                  <input type="text" id="callInput" class="wid150" v-model="formData.actShprCstEnm">
                </div>
              </li>
            </ul>
            <ul class="grid t3 mt10">
              <e-date-range-picker
                @change="changeDateRange"
                :sdate="formData.fromDt"
                :edate="formData.toDt"
                :title="this.$t('msg.NEWB010P020.005')"
              />
            </ul>
            <div class="booking_search_btn">
              <button class="button sm blue" type="button" @click.prevent="search()">{{ $t('msg.NEWB010P020.006') }}</button><!-- 검색 -->
            </div>
          </div>
        </form>
      </div><!-- content_box // -->
      <div class="content_box mt10">
        <div class="flex_box">
          <span class="tot_num">{{ $t('msg.NEWB010P020.007') }} : <span class="num">{{ total }}</span></span><!-- Total -->
        </div>
        <div id="realgrid" style="width: 100%; height: 200px" />
      </div>
    </div><!-- popup_cont -->
  </div>
</template>

<script>
// TODO
// date 적용하기.

import commons from '@/api/services/commons'
import blCertificate from '@/api/rest/trans/blCertificate'
import { GridView, LocalDataProvider } from 'realgrid'
import EAutoCompletePlace from '@/components/common/EAutoCompletePlace'
import EDateRangePicker from '@/components/common/EDateRangePicker'

let gridView = GridView
let provider = LocalDataProvider

export const fields = [
  {
    fieldName: 'blNo',
    dataType: 'text'
  },
  {
    fieldName: 'crtfNo',
    dataType: 'text'
  },
  {
    fieldName: 'actShprCstEnm',
    dataType: 'text'
  },
  {
    fieldName: 'polPortNm',
    dataType: 'text'
  },
  {
    fieldName: 'podPortNm',
    dataType: 'text'
  },
  {
    fieldName: 'cntrCnt',
    dataType: 'text'
  },
  {
    fieldName: 'cgoTypCd',
    dataType: 'text'
  },
  {
    fieldName: 'cmdtDsc',
    dataType: 'text'
  }
]

export const columns = [
  {
    name: 'blNo',
    fieldName: 'blNo',
    type: 'text',
    width: '150',
    header: {
        text: 'B/L No.'
    },
    editable: false,
    readOnly: false,
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        const data = cell.value
        let str = ''
        str = '<a style="text-decoration:underline;color:#075bb9" @click.prevent="selectedRow">' + data + '</a>'
        return str
      }
    }
  },
  {
    name: 'crtfNo',
    fieldName: 'crtfNo',
    type: 'text',
    width: '100',
    header: {
      text: 'Ref No.'
    },
    readOnly: true
  },
  {
    name: 'actShprCstEnm',
    fieldName: 'actShprCstEnm',
    type: 'text',
    width: '100',
    header: {
      text: app.$t('msg.NEWB010P020.010')
    },
    readOnly: true
  },
  {
    name: 'polPortNm',
    fieldName: 'polPortNm',
    type: 'text',
    width: '150',
    header: {
      text: app.$t('msg.NEWB010P020.002')
    },
    readOnly: true
  },
  {
    name: 'podPortNm',
    fieldName: 'podPortNm',
    type: 'text',
    width: '150',
    header: {
      text: app.$t('msg.NEWB010P020.003')
    },
    readOnly: true
  },
  {
    name: 'cntrCnt',
    fieldName: 'cntrCnt',
    type: 'text',
    width: '100',
    header: {
      text: 'Container'
    },
    readOnly: true
  },
  {
    name: 'cgoTypCd',
    fieldName: 'cgoTypCd',
    header: { text: 'Cargo Type' },
    width: '120',
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const cgoTypCd = grid.getValue(idx, 'cgoTypCd')
        const btnHtml = []
        if (cgoTypCd.includes(',')) {
          const arrCagoTypCd = cgoTypCd.split(',')
          arrCagoTypCd.forEach(cd => {
            btnHtml.push('<p>' + thisApp.getCodeNm('01038', cd.trim()) + '</p>')
          })
        } else {
          if (cgoTypCd === 'Dry') {
            btnHtml.push('<p>' + cgoTypCd + '</p>')
          } else {
            btnHtml.push('<p>' + thisApp.getCodeNm('01038', cgoTypCd.trim()) + '</p>')
          }
        }
        return btnHtml.join(' ')
      }
    }
  },
  {
    name: 'cmdtDsc',
    fieldName: 'cmdtDsc',
    type: 'text',
    width: '100',
    header: {
      text: 'Commodity'
    },
    readOnly: true
  }
]

export default ({
  name: 'BookingCallPop',
  components: {
    EAutoCompletePlace,
    EDateRangePicker
  },
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          searchFlag: '', //BL불러오기(BL) or BL Cetificate 불러오기(CRTF)
          polPortNm: '',
          podPortNm: '',
          actShprCstEnm: ''
        }
      }
    }
  },
  data () {
    return {
      items: [],
      formData: {
        searchFlag: this.parentInfo.searchFlag,
        dtKnd: '',
        podCtrCd: '',
        polCtrCd: '',
        actShprCstEnm: '',
        fromDt: '',
        toDt: ''
      },
      total: 0,
      commonCodes: {}
    }
  },
  created () {
    this.initCommCodes()
    window.thisApp = this
    this.formData.searchFlag = this.$ekmtcCommon.isNotEmpty(this.parentInfo.searchFlag) ? this.parentInfo.searchFlag : 'BL'
    //B/L Certificate에서 사용시 Certificate No 컬럼 보여주기
    if (this.formData.searchFlag !== 'CRTF') {
      columns.splice(1, 1)
    }

    this.formData.fromDt = this.$ekmtcCommon.getAddDay('', -14)
    this.formData.toDt = this.$ekmtcCommon.getDateToStrDelim(new Date(), '')
  },
  mounted: function () {
      const $vm = this
      //데이터를 담는 객체
      provider = new LocalDataProvider(true)
      //데이터를 보여주기 위한 객체
      gridView = new GridView('realgrid')
      gridView.setDataSource(provider)
      //그리드 필드 생성
      provider.setFields(fields)
      //column명
      gridView.setColumns(columns)

      gridView.displayOptions.fitStyle = 'fill'
      gridView.displayOptions.rowHeight = 30
      gridView.setEditOptions({
        editable: false,
        updatable: false
      })

      //인디케이터 없애기
      gridView.setRowIndicator({
        visible: false
      })
      //상태바 없애기
      gridView.setStateBar({
        visible: false
      })
      //체크바 없애기
      gridView.setCheckBar({
        visible: false
      })
      //Grid 메소드
      $vm.gridEvents()
  },
  methods: {
    // 공통코드 불러오기
    async initCommCodes () {
      const cdId = ['01038']
      this.commonCodes = await commons.getCommonsCodesCommon(cdId)
    },
    getCodeNm (cdId, cd) {
      const list = this.commonCodes[cdId]
      const vo = list.find(vo => vo.cd === cd)
      if (vo === undefined) {
        return ''
      } else {
        return vo.cdNm
      }
    },
    search: function () {
      if (this.formData.fromDt && this.formData.toDt) {
        this.formData.dtKnd = 'OD'
      } else {
        this.formData.dtKnd = ''
      }

      this.items = []
      //api 호출
      if (this.formData.podCtrCd !== '' || this.formData.polCtrCd !== '' || this.formData.actShprCstEnm !== '' || this.formData.fromDt !== '' || this.formData.toDt !== '') {
        blCertificate.getBlCall(this.formData).then((result) => {
          const list = result.data
          list.forEach((item) => {
            this.items.push(item)
          })

          if (list.length !== 0) {
            //해당 검색어를 검색한 리스트 to array

            //그리드 데이터 세팅
            provider.setRows(this.items)

            //total
            this.total = list.length
            //alert('적용되었습니다.')
            return false
          } else {
            this.$ekmtcCommon.alertDefault(this.$t('msg.NEWB010P020.008')) // 검색 결과가 없습니다.\n  다시 검색 바랍니다.
          }
        })
        return false
      } else {
         this.$ekmtcCommon.alertDefault(this.$t('msg.MAIN010G030.032')) // '검색어를 입력해주세요'
      }
    },
    gridEvents () {
      const TH = this
      gridView.onCellClicked = function (grid, clickData) {
        const current = gridView.getCurrent()
        const fieldName = current.fieldName
        const clickedField = provider.getValue(current.dataRow, current.fieldName)
        //선택 버튼 제어
        if (fieldName === 'blNo') {
          if (clickedField === '1') {
            // alert('선택불가')
          } else {
            const selectedData = provider.getValues(clickData.dataRow)
            console.log(selectedData)
            const obj = {
              blNo: selectedData[0],
              crtfNo: selectedData[1]
            }
            TH.$emit('selectFunc', obj)
            TH.$emit('close')
          }
        }
      }
    },
    layerClose: function () {
      this.$emit('close')
      return false
    },
    changePol (vo) {
      const def = {
        ctrCd: '',
        plcCd: ''
      }
      const tvo = { ...def, ...vo }

      this.formData.polCtrCd = tvo.ctrCd
      this.formData.polPortCd = tvo.plcCd
    },
    changePod (vo) {
      const def = {
        ctrCd: '',
        plcCd: ''
      }
      const tvo = { ...def, ...vo }

      this.formData.podCtrCd = tvo.ctrCd
      this.formData.podPortCd = tvo.plcCd
    },
    changeDateRange (s, d) {
      this.formData.fromDt = s
      this.formData.toDt = d
    }
  }
})
</script>
